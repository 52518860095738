<template>
    <div>
        <div class="box">
            <div class="logo">
                <img :src="src" alt="">
            </div>
            <div class="information">
                <div class="name">
                    <div class="xm-box">
                        <img src="../../assets/image/user.png" alt="">
                        <div class="xm">姓名：</div>
                    </div>
                    <div v-if="this.$store.state.realnameornot.requeststatus == 1 || this.$store.state.realnameornot.caStatus == 1">
                        <div class="xm" v-if="realnameLength">{{ this.date.user.realname }}</div>
                        <el-tooltip v-else class="item" effect="dark" :content="this.date.user.realname" placement="top-start">
                            <div class="xm xms">{{ this.date.user.realname }}</div>
                        </el-tooltip>
                    </div>
                    <div v-else class="xm"></div>
                </div>
                <div class="name name2">
                    <div class="xm-box">
                        <img src="../../assets/image/gender.png" alt="">
                        <div class="xm">性别：</div>
                    </div>
                    <div class="xm" v-if="this.$store.state.realnameornot.requeststatus == 1 || this.$store.state.realnameornot.caStatus == 1">{{ this.date.user.sex == 1 ? '男' : (this.date.user.sex == 2 ? '女' : '') }}</div>
                    <div v-else></div>
                </div>
                <el-upload ref='upload' :drag="true" :limit="1" :data="upData" :action="uploadUrl" :on-success="success"
                    :headers="headers" :multiple="false" :file-list="uploadfiles" :show-file-list="false"
                    :before-upload="beforeAvatarUpload" accept="image/jpg,image/jpeg,image/png">
                    <div class="buton">
                        修改头像
                    </div>
                </el-upload>
            </div>
        </div>

        <div class="nav">
            <div @click="getnav(1)" :class="navIndex == 1 ? 'select' : ''">裁判员</div>
            <div @click="getnav(2)" :class="navIndex == 2 ? 'select' : ''">运动员</div>
            <!-- <div @click="getnav(3)" :class="navIndex == 3 ? 'select' : ''">教练员</div> -->
        </div>
        <div class="xian"></div>

        <div v-if="navIndex == 1">
            <div class="hd" style="width: auto;" v-if="date.referee.certificateList.length > 0">
                <div class="zuo" @click="getzuo">
                    <img src="../../assets/image/zuo.png" alt="">
                </div>
                <div class="xx" ref="xxContainer">
                    <div class="diyc-box" v-for="(item, index) in this.date.referee.certificateList" :key="index"
                        v-if="index >= currentIndex && index < currentIndex + 3">
                        <div class="diyc">
                            <div class="diyczuo">
                                <img src="../../assets/image/logo.png" alt="">
                                <div class="name">{{ item[0].sportName }}</div>
                            </div>
                            <div class="diycyou">{{ item[0].referee_level }}</div>
                        </div>
                        <div class="diycxian"></div>
                        <div class="diyctitle" v-if="item[0].certificate_unit.length <= 11">发证单位：{{
                    item[0].certificate_unit }} </div>
                        <div class="diyctitle" v-else>
                            <el-tooltip class="item" effect="dark" :content="item[0].certificate_unit"
                                placement="top-start">
                                <div>发证单位：{{ item[0].certificate_unit.slice(0, 11) }}...</div>
                            </el-tooltip>
                        </div>
                        <div class="diyctitle">发证日期：{{ item[0].certificate_date | format }}</div>
                        <div class="diyctitle">注册所在区：{{ item[0].certificate_area }}</div>
						<div class="diyctitle">注册状态：
							{{item[0].apply_for_status == 40 ? '已注册' : (item[0].apply_for_status == 60 ?'未年度注册 ':item[0].apply_for_status) }}
						</div>
                    </div>
                </div>

                <div class="you" @click="getyou">
                    <img src="../../assets/image/you.png" alt="">
                </div>
            </div>

            <div class="navt">
                <div class="navtxian"></div>
                <div class="navtname">执裁记录</div>
            </div>

            <div class="arbitration-box" v-for="(item, index) in this.date.referee.enforcementList" :key="index">
                <div>
                    <div class="arbitration" @click="getarbitration(item[0])">
                        <div class="arbitrationname">
                            {{ item[0].match_sport }}
                        </div>
                        <div :class="item[0].arbitration ? 'triangle-down' : 'triangle-right'"></div>
                    </div>
                    <div class="timeline"  v-if="item[0].arbitration">
                        <el-timeline>
                            <el-timeline-item :timestamp="res.match_date" color="#0063FF" v-for="(res, indexs) in item" :key="indexs">
                                {{ res.match_name }}
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>

            <div v-if="this.$verification(this.date.referee.enforcementList)" class="zx-box" style="margin-top: 0;">
                <img class="zw" src="../../assets/image/333.png" alt="">
                <div class="zwxx">暂无信息</div>
            </div>

            <div class="navt" style="margin-top: 0;">
                <div class="navtxian"></div>
                <div class="navtname">晋升记录</div>
            </div>

            <div class="arbitration-box promotion" v-for="(item, index) in this.date.referee.promotionList" :key="index"
                :style="{ background: item[0].arbitration ? '#fff' : '', width: 'auto' }">
                <div>
                    <div class="arbitration" @click="getarbitration(item[0])">
                        <div class="arbitrationname">
                            {{ item[0].sportsName }}
                        </div>
                        <div :class="item[0].arbitration ? 'triangle-down' : 'triangle-right'"></div>
                    </div>
                    <div class="timelines" v-if="item[0].arbitration" v-for="(res, indexs) in item" :key="indexs">
                        <div>
                            <div class="jtxx-box">
                                <div class="jtxxy">
                                    <div class="jb">
                                        <img src="../../assets/image/111.png" alt="">
                                        <div style="flex-shrink: 0;">晋升级别：</div>
                                        <div>{{ res.refereeLevel }}</div>
                                    </div>
                                    <div class="rq">
                                        <img src="../../assets/image/222.png" alt="">
                                        <div style="flex-shrink: 0;">发证日期：</div>
                                        <div>{{ res.certificateDate | format }}</div>
                                    </div>
                                </div>
                                <div class="dz">
                                    <img src="../../assets/image/99.png" alt="" style="margin-right: 15PX;">
                                    <div style="flex-shrink: 0;">发证单位：</div>
                                    <div>{{ res.certificateUnit }}</div>
                                </div>
                            </div>
                            <div class="jtxxxian" v-if="indexs != item.length - 1"></div>
                            <div class="jtxxkb" v-if="indexs == item.length - 1"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="this.$verification(this.date.referee.promotionList)" class="zx-box" style="margin-top: 0;">
                <img class="zw" src="../../assets/image/333.png" alt="">
                <div class="zwxx">暂无信息</div>
            </div>


        </div>

        <div v-if="navIndex == 2">
            <div class="zx-box" v-if="Object.keys(this.date.sportsman).length === 0">
                <img class="zw" src="../../assets/image/333.png" alt="">
                <div class="zwxx">暂无信息</div>
            </div>
            <div class="ydy-box" v-else>
                <div class="ydy" v-for="(item, index) in this.date.sportsman.sportsList" :key="index">
                    <div class="name-box">
                        <div class="ydyxian"></div>
                        <div class="name">{{ item }}</div>
                    </div>
                    <div class="ydybut">
                        <div class="ydybutname" @click="getentry(item)">参赛记录</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="navIndex == 3" class="zx-box">
            <img class="zw" src="../../assets/image/333.png" alt="">
            <div class="zwxx">暂无信息</div>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
            <div class="table" style="margin: 0;">
                <el-table :data="tableData" border style="width: 100%;" :header-cell-style="{ background: '#0A6DF3' }">
                    <el-table-column label="项目" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.sports }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="matchName" label="赛事名称" align="center">
                    </el-table-column>
                    <el-table-column label="赛事时间" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.matchDateStart }} 至 {{ scope.row.matchDateEnd }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="matchAddressStadium" label="地点" align="center">
                    </el-table-column>
                    <el-table-column prop="gameGroup" label="组别" align="center">
                    </el-table-column>
                    <el-table-column prop="event" label="小项" align="center">
                    </el-table-column>
                    <el-table-column prop="games" label="赛次（轮次）" align="center">
                    </el-table-column>
                    <el-table-column prop="delegation" label="代表队" align="center">
                    </el-table-column>
                    <el-table-column prop="names" label="姓名" align="center">
                    </el-table-column>
                    <el-table-column prop="grade" label="成绩" align="center">
                    </el-table-column>
                    <el-table-column prop="ranking" label="名次" align="center" width="55">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" align="center">
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import { Loading } from 'element-ui';
export default {
    data() {
        return {
            navIndex: 1,
            options2: [],
            options3: [],
            date: {
                referee: {
                    certificateList: [],
                    enforcementList: [],
                    promotionList: []
                },
                user: {},
                sportsman: {}
            },
            currentIndex: 0,
            dialogVisible: false,
            tableData: [],
            src: require('@/assets/image/default.png'),
            uploadUrl: process.env.VUE_APP_BASE_API + '/api/upload',
            upData: {
                fileExtensionList: ["jpg", "png", "jpeg"],
            },
            fileList1: [],
            uploadfiles: [],
            headers: {
                "token": this.$store.state.token
            },
            realnameLength:true
        }
    },
    filters: {
        format(x) {
            if (x != null && x != '') {
                let time = x.split(" ")[0]
                let parts = time.split('-');
                let result = parts[0] + '-' + parts[1];
                return result
            }
        }
    },
    created() {
        this.judgmentLevel()
        this.areaList()
        this.getUserCardInfo()
    },
    methods: {
        judgmentLevel() {
            this.$api.judgmentLevel().then(res => {
                this.options2 = res.data.data.result
            })
        },
        areaList() {
            this.$api.areaList().then(res => {
                this.options3 = res.data.data.result
            })
        },
        getUserCardInfo() {
            this.$api.getUserCardInfo({ userId: this.$store.state.userid }).then(res => {
                if(!this.$verification(res.data.data.result.user.headPic)){
                    this.$api.getFile({ objectName: res.data.data.result.user.headPic }).then(ess => {
                        this.$convert(ess.data.data.result.url).then((fileUrl) => {
                           this.src = fileUrl
                            this.$forceUpdate()
                        })
                    })
                }

                res.data.data.result.referee.certificateList.forEach(item => {
                    item.forEach(err => {
                        err.realName = this.$decrypt(err.realName || '')
                        err.certificate_unit = this.$decrypt(err.certificate_unit || '')

                        for (let i in this.options2) {
                            if (err.referee_level == this.options2[i].itemValue) {
                                err.referee_level = this.options2[i].itemText
                            }
                        }
                        for (let i in this.options3) {
                            if (err.certificate_area == this.options3[i].itemValue) {
                                err.certificate_area = this.options3[i].itemText
                            }
                        }
                    })
                })

                res.data.data.result.referee.enforcementList.forEach(item => {
                    item.forEach(res => {
                        let match_date = JSON.parse(res.match_date)
                        let arr = match_date.map(err => { return err.startDate + ' 至 ' + err.endDate })
                        res.match_date = arr.join("，")
                        res.arbitration = true
                    })
                })

                res.data.data.result.referee.promotionList.forEach(item => {
                    item.arbitration = true
                    item.forEach(res => {
                        res.certificateUnit = this.$decrypt(res.certificateUnit || '')
                        res.arbitration = true
                    })
                })
                res.data.data.result.user.realname = this.$decrypt(res.data.data.result.user.realname || '')
                res.data.data.result.user.phone = this.$decrypt(res.data.data.result.user.phone || '')
                if( res.data.data.result.user.realname.length > 10){
                    this.realnameLength = false
                }else{
                    this.realnameLength = true
                }
                this.date = res.data.data.result
            })
        },
        getnav(e) {
            this.navIndex = e
        },
        getarbitration(e) {
            e.arbitration = !e.arbitration
        },
        handleClose() {
            this.dialogVisible = false
        },
        getzuo() {
            if (this.currentIndex > 0) {
                this.currentIndex -= 2;
            }
        },
        getyou() {
            if (this.currentIndex + 3 < this.date.referee.certificateList.length) {
                this.currentIndex += 2;
            }
        },
        getentry(item) {
            this.dialogVisible = true
            let data = JSON.parse(JSON.stringify(this.date.sportsman))
            data.sports = item
            this.$api.getSportsmanScore(data).then(res => {
                res.data.data.result.forEach(item => {
                    item.names = this.$decrypt(item.names || '')
                    item.matchAddressStadium = item.addresses.map(items => {
                        return items.matchAddressStadium
                    }).join('、')
                })
                this.tableData = res.data.data.result
            })
        },
        beforeAvatarUpload(file) {
            console.log(file.type.lastIndexOf("/"))
            let index = file.type.lastIndexOf("/")
            let ext = file.type.substr(index + 1)
            let formats = ['png', 'jpg', 'jpeg']
            const isLt5M = file.size / 1024 / 1024 < 5

            const isJPG = formats.includes(ext)

            if (!isJPG) {
                this.$message.error('文件格式不符合！仅支持png、jpg、jpeg格式');
            } else if (!isLt5M) {
				this.$message.error('上传文件大小不能超过5M!')
            }
            return isJPG && isLt5M;
        },
        success(response, file, fileList) {
            if (response.code == -1) {
                this.$alert('登录状态已过期，请重新登录', '系统提示', {
                    confirmButtonText: '重新登录',
                    type: 'warning'
                }).then(async () => {
                    //注销去登录页
                    this.$store.commit("EXIT_TOKEN")
                    if (window.location.href.includes('/h5')) {
                        location.href = '/h5/login'
                    } else {
                        location.href = '/login'
                    }
                })
                this.$refs.upload.clearFiles()
                return
            }
            if (response.code != 200) {
                this.$refs.upload.clearFiles()
                return this.$message.error(response.msg)
            }else{
                let data = {
                    id:this.date.user.id,
                    headPic:response.data.message
                }
                this.$api.updateAvatar(data).then(res=>{
                    this.$message.success('上传成功')
                    this.getUserCardInfo()
                })
            }
        },
    }

}
</script>

<style lang="scss" scoped>
.box {
    width: 931PX;
    height: 269PX;
    background: url('../../assets/image/55.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 35PX;
    display: flex;

    .logo {
        width: 170PX;
        height: 200PX;
        border-radius: 20PX;
        margin-top: 32PX;
        margin-left: 29PX;
        img{
            border-radius: 20PX;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }

    .information {
        margin-left: 28PX;

        .name {
            display: flex;
            width: 300PX;
            border-bottom: 1PX solid rgba(110, 110, 110, 0.48);
            height: 40PX;
            display: flex;
            justify-content: space-between;
            margin-top: 49PX;

            img {
                width: 18.2PX;
                height: 20.3PX;
                margin-right: 24PX;
            }

            .xm-box {
                display: flex;

                .xm {
                    font-family: SourceHanSansCN;
                    font-weight: 500;
                    font-size: 18PX;
                    color: #000000;
                    margin-right: 15PX;
                    flex-shrink: 0;
                }
            }

            .xm {
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 18PX;
                color: #000000;
                margin-right: 15PX;
            }

            .xms{
                width: 60%;
                overflow: hidden;
                height: 25PX;
                text-align: right;
                white-space: nowrap; /* 确保文本在一行内显示 */
                overflow: hidden; /* 隐藏超出容器的文本 */
                text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
            }
            .item{
                color:#437DCA;
            }
        }

        .name2 {
            margin-top: 25PX;
        }

        .buton {
            width: 300PX;
            height: 54PX;
            background: url('../../assets/image/66.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            color: rgba(29, 79, 206, 1);
            font-size: 21PX;
            font-family: SourceHanSansCN-Bold;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        ::v-deep .el-upload-dragger {
            height: 54PX;
            margin-top: 27PX;
            border: none;
            background: none;
        }
    }
}

.nav {
    display: flex;
    margin-top: 27PX;

    div {
        width: 180PX;
        height: 50PX;
        background: #E8F2FF;
        border-radius: 20PX 20PX 0PX 0PX;
        font-family: SourceHanSansCN;
        font-weight: bold;
        font-size: 23PX;
        color: #437DCA;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16PX;
        cursor: pointer;
    }
}

.xian {
    width: 951PX;
    height: 2PX;
    background: #85B0E9;
    border-radius: 1PX;
}

.select {
    background: linear-gradient(90deg, #6FB2FF, #095FFF) !important;
    box-shadow: 0PX 7PX 16PX 0PX rgba(50, 129, 255, 0.51);
    border-radius: 20PX 20PX 0PX 0PX;
    color: #ffffff !important;
}

.hd {
    margin-top: 65PX;
    display: flex;
    width: 836PX;

    .zuo {
        width: 44PX;
        height: 44PX;
        margin-right: 13PX;
        margin-top: 51PX;
        cursor: pointer;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .you {
        width: 44PX;
        height: 44PX;
        margin-left: 13PX;
        margin-top: 51PX;
        cursor: pointer;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .xx {
		display: flex;
		width: 1041PX;
		overflow: hidden;

		.diyc-box {
			width: 308PX;
			height: 204PX;
			background: url('../../assets/image/88.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin-right: 39PX;

			.diyc {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.diyczuo {
					display: flex;
					padding-top: 28PX;
					padding-left: 22PX;

					img {
						width: 39PX;
						height: 37PX;
					}

					.name {
						font-family: SourceHanSansCN;
						font-weight: bold;
						font-size: 18PX;
						color: #1469FF;
						margin-left: 8PX;
						display: flex;
						align-items: center;
					}
				}

				.diycyou {
					width: 60PX;
					height: 23PX;
					background: url('../../assets/image/77.png');
					background-repeat: no-repeat;
					background-size: 100% 100%;
					font-family: SourceHanSansCN;
					font-weight: bold;
					font-size: 14PX;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					top: -5PX;
					right: 38PX;
				}
			}

			.diycxian {
				width: 256PX;
				height: 1PX;
				background: #7FB5FD;
				border-radius: 0PX;
				margin: 15PX 0 19PX 30PX;
			}

			.diyctitle {
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 15PX;
				color: #5E5E5E;
				margin-bottom: 2PX;
				margin-left: 29PX;
			}
		}
	}
}

.navt {
    width: 926PX;
    height: 58PX;
    background: #FFFFFF;
    box-shadow: 5PX 14PX 18PX 3PX rgba(170, 202, 237, 0.16);
    border-radius: 19PX;
    margin: 67PX 0 34PX 0;
    display: flex;
    align-items: center;

    .navtxian {
        width: 9PX;
        height: 23PX;
        background: #2A7AFF;
        border-radius: 5PX;
        margin-left: 38PX;
    }

    .navtname {
        font-family: SourceHanSansCN;
        font-weight: bold;
        font-size: 26PX;
        color: #2A7AFF;
        margin-left: 21PX;
    }
}

.arbitration-box {
    width: 836PX;

    .arbitration {
        min-width: 115PX;
        height: 38PX;
        background: linear-gradient(150deg, #0D63FF, #3280FF);
        border-radius: 19PX 19PX 19PX 0PX;
        display: inline-block;
        cursor: pointer;
        line-height: 38PX;
        margin-bottom: 29PX;
        padding: 0 19PX;
    }

    .arbitrationname {
        font-family: SourceHanSansCN;
        font-weight: bold;
        font-size: 20PX;
        color: #FFFFFF;
        margin: 0 19PX;
        display: inline-block;
    }

    .triangle-down {
        width: 0;
        height: 0;
        border-left: 4PX solid transparent;
        /* 左边框为透明，宽度为4PX */
        border-right: 4PX solid transparent;
        /* 右边框为透明，宽度为4PX */
        border-top: 7PX solid #FFFFFF;
        /* 上边框为白色，高度为7PX */
        display: inline-block;
        position: relative;
        top: -2PX;
    }

    .triangle-right {
        width: 0;
        height: 0;
        border-top: 4PX solid transparent;
        /* 上边框为透明，高度为4PX */
        border-bottom: 4PX solid transparent;
        /* 下边框为透明，高度为4PX */
        border-left: 7PX solid #FFFFFF;
        /* 左边框为白色，宽度为7PX */
        display: inline-block;
        position: relative;
        top: -2PX;
    }

    .timeline {
        margin-top: 20PX;
        margin-left: 45PX;

        ::v-deep .el-timeline-item__content {
            font-family: SourceHanSansCN;
            font-weight: 500;
            font-size: 19PX;
            color: #000000;
        }

        ::v-deep .el-timeline-item__timestamp {
            font-family: SourceHanSansCN;
            font-weight: 500;
            font-size: 15PX;
            color: #9B9B9B;
        }

        ::v-deep .el-timeline-item__tail {
            border-left: 2PX solid #0063FF !important;
        }

        ::v-deep .el-timeline-item {
            padding-bottom: 40PX !important;
        }
    }
}

.promotion {
    border-radius: 19PX;

    .timelines {
        .jtxx-box {
            margin-left: 35PX;

            .jtxxy {
                display: flex;
                align-items: center;
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 19PX;
                color: #000000;

                .jb {
                    display: flex;

                    img {
                        width: 20.2PX;
                        height: 26.3PX;
                    }

                    div:nth-child(3) {
                        color: #2D7CFF;
                    }
                }

                .rq {
                    display: flex;
                    position: absolute;
                    margin-left: 282PX;

                    img {
                        width: 22.1PX;
                        height: 20.5PX;
                    }
                }
            }

            .dz {
                display: flex;
                align-items: center;
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 19PX;
                color: #000000;
                margin-top: 33PX;

                img {
                    width: 26PX;
                    height: 20PX;
                }

            }

            img {
                margin-right: 20PX;
            }
        }

        .jtxxxian {
            height: 1PX;
            background: #D3D3D3;
            border-radius: 0PX;
            margin: 30PX 35PX;
        }

        .jtxxkb {
            padding-bottom: 35PX;
            margin-bottom: 35PX;
        }
    }
}

.zx-box {
    margin-top: 202PX;
    display: flex;
    flex-direction: column;
    align-items: center;

    .zw {
        width: 257PX;
        height: 162PX;
    }

    .zwxx {
        font-family: SourceHanSansCN;
        font-weight: 500;
        font-size: 22PX;
        color: #989898;
        margin-top: 27PX;
        margin-bottom: 40PX;
    }
}

.ydy-box {
    margin: 47PX 0 0 0;
    width: 950PX;
    display: flex;
    flex-wrap: wrap;


    .ydy {
        width: 293PX;
        height: 172PX;
        background: url('../../assets/image/555.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 32PX;
        margin-bottom: 40PX;

        .name-box {
            display: flex;
            align-items: center;
            margin-left: 26PX;
            padding-top: 30PX;

            .ydyxian {
                width: 6PX;
                height: 19PX;
                background: #417BC8;
                border-radius: 3PX;
            }

            .name {
                font-family: SourceHanSansCN;
                font-weight: bold;
                font-size: 26PX;
                color: #408BFF;
                line-height: 36PX;
                background: linear-gradient(150deg, #336EBC 0.146484375%, #81B7FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-left: 21PX;
            }
        }

        .ydybut {
            width: 139PX;
            height: 36PX;
            background: url('../../assets/image/444.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            margin-top: 45PX;
            margin-left: 104PX;
            cursor: pointer;

            .ydybutname {
                font-family: SourceHanSansCN;
                font-weight: 500;
                font-size: 22PX;
                color: #629AE4;
                margin-left: 40PX;
            }
        }
    }

    .ydy:nth-child(3n) {
        margin-right: 0;
    }
}

.table ::v-deep .el-table thead {
    color: #fff;
    font-size: 16PX;
    font-weight: 500;
}

.table ::v-deep .el-table__header-wrapper {
    border-top-left-radius: 10PX;
    border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
    border-top-left-radius: 10PX;
    border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 1PX solid #333333;
    border-right: 1PX solid #333333;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
    border-color: #333333;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
    background-color: #333333;
}
::v-deep .el-dialog__body{
	max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
}
</style>
