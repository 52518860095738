<template>
  <div>
    <div class="box">
      <div class="zou">
        <div class="group_2 flex-row">
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="../../assets/image/44.png"
          />
          <span class="text_8 text_88" @click="menuClick(0)">个人主页</span>
        </div>
        <div class="group_36 flex-row justify-between">
          <img
            class="thumbnail_2"
            referrerpolicy="no-referrer"
            src="../../assets/image/11.png"
          />
          <span class="text_9">我是裁判员</span>
        </div>
        <div class="group_37 flex-row justify-between">
          <div class="block_2 flex-col"></div>
          <span
            class="text_10"
            @click="menuClick(1)"
            :style="{ color: menuIndex == 1 ? '#408ed6' : '#000000' }"
            >年度注册</span
          >
        </div>
        <div class="group_37 flex-row justify-between">
          <div class="block_2 flex-col"></div>
          <span
            class="text_10"
            @click="menuClick(2)"
            :style="{ color: menuIndex == 2 ? '#408ed6' : '#000000' }"
            >执裁记录</span
          >
        </div>
        <div class="image-text_33 flex-row justify-between">
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="../../assets/image/22.png"
          />
          <span class="text-group_1">我是运动员</span>
        </div>
        <div class="group_37 flex-row justify-between">
          <div class="block_2 flex-col"></div>
          <span
            class="text_10"
            @click="menuClick(3)"
            :style="{ color: menuIndex == 3 ? '#408ed6' : '#000000' }"
            >我的报名</span
          >
        </div>
        <div class="group_37 flex-row justify-between">
          <div class="block_2 flex-col"></div>
          <span
            class="text_10"
            @click="menuClick(6)"
            :style="{ color: menuIndex == 6 ? '#408ed6' : '#000000' }"
            >赛事成绩</span
          >
        </div>
        <div class="image-text_34 flex-row justify-between">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="../../assets/image/33.png"
          />
          <span class="text-group_2">安全中心</span>
        </div>
        <div class="group_37 flex-row justify-between">
          <div class="block_2 flex-col"></div>
          <span
            class="text_10"
            @click="menuClick(4)"
            :style="{ color: menuIndex == 4 ? '#408ed6' : '#000000' }"
            >身份验证</span
          >
        </div>
        <div class="group_37 flex-row justify-between">
          <div class="block_2 flex-col"></div>
          <span
            class="text_10"
            @click="menuClick(7)"
            :style="{ color: menuIndex == 7 ? '#408ed6' : '#000000' }"
            >照片授权</span
          >
        </div>
        <div class="group_37 flex-row justify-between">
          <div class="block_2 flex-col"></div>
          <span
            class="text_10"
            @click="menuClick(5)"
            :style="{ color: menuIndex == 5 ? '#408ed6' : '#000000' }"
            >登录设置</span
          >
        </div>
      </div>
      <div class="you">
        <homepage v-if="menuIndex == 0"></homepage>
        <!-- <div v-if="menuIndex == 3" style="height: 100%;display: flex;  align-items: center;">
					<img src="../../assets/image/build.png" alt="">
				</div> -->
        <match v-if="menuIndex == 3"></match>

        <refereeRegistration
          v-if="menuIndex == 1 && step == 1"
          @newlyadded="newlyadded"
        ></refereeRegistration>

        <refereeDetails
          v-if="menuIndex == 1 && step != 1"
          @toexamine="toexamine"
          :step="step"
          :id="id"
        >
        </refereeDetails>

        <record v-if="menuIndex == 2"></record>

        <personalInformation
          v-if="nameornot && menuIndex == 4"
          @recertification="recertification"
        >
        </personalInformation>

        <personalAuthentication
          :edit="edit"
          v-if="!nameornot && menuIndex == 4"
          @toexamine="recertification1"
          @authorization="authorization"
          :distinguish="distinguish"
        ></personalAuthentication>

        <div v-if="menuIndex == 5">
          <div class="back" v-if="!telshow && !passshow">
            <div class="tel">
              <img src="../../assets/image/tel.png" alt="" />
              <p class="telnumber">登录手机</p>
              <input
                type="text"
                style="margin-left: 39px"
                v-model="userdate.phone"
                readonly
              />
              <div class="but2" @click="modifytel">修改</div>
            </div>
            <div class="tel" style="padding-top: 23px">
              <img
                src="../../assets/image/password.png"
                alt=""
                style="width: 20.2px; height: 23.9px"
              />
              <p class="telnumber">登录密码</p>
              <input
                type="text"
                style="margin-left: 36px"
                readonly
                v-model="pas"
              />
              <div class="but2" @click="modifypass">修改</div>
            </div>
          </div>
          <div class="back" v-if="telshow">
            <div class="tel">
              <img src="../../assets/image/tel.png" alt="" />
              <p class="telnumber">旧手机号：</p>
              <input
                type="text"
                style="margin-left: 39px"
                v-model="modifyphone.oldPhone"
                readonly
              />
            </div>
            <div class="tel" style="padding-top: 23px">
              <img src="../../assets/image/tel.png" alt="" />
              <p class="telnumber">新手机号：</p>
              <input
                type="text"
                style="margin-left: 39px"
                v-model="modifyphone.newPhone"
              />
              <div
                class="but"
                @click="startCountdown"
                :disabled="isCountingDown"
              >
                {{ buttonText }}
              </div>
            </div>
            <div class="tel" style="padding-top: 23px">
              <img
                src="../../assets/image/code.png"
                alt=""
                style="width: 20.2px; height: 23.9px"
              />
              <p class="telnumber">验证码 ：</p>
              <input
                type="text"
                style="margin-left: 48px"
                v-model="modifyphone.phoneCode"
              />
            </div>
            <div class="submitto" @click="getiphone">提交</div>
          </div>
          <div class="back" v-if="passshow">
            <div class="tel" style="padding-top: 23px">
              <img
                src="../../assets/image/code.png"
                alt=""
                style="width: 20.2px; height: 23.9px"
              />
              <p class="telnumber" style="margin-right: 32px">旧密码：</p>
              <el-input
                show-password
                class="custom-input"
                v-model="changepassword.oldPassword"
              ></el-input>
            </div>
            <div class="tel" style="padding-top: 23px">
              <img
                src="../../assets/image/code.png"
                alt=""
                style="width: 20.2px; height: 23.9px"
              />
              <p class="telnumber" style="margin-right: 32px">新密码：</p>
              <el-input
                show-password
                class="custom-input"
                v-model="changepassword.newPassword"
              ></el-input>
            </div>
            <div class="tel" style="padding-top: 23px">
              <img
                src="../../assets/image/password.png"
                alt=""
                style="width: 20.2px; height: 23.9px"
              />
              <p class="telnumber" style="margin-right: 15px">确认密码：</p>
              <el-input
                show-password
                class="custom-input"
                v-model="changepassword.confirm"
              ></el-input>
            </div>
            <div class="submitto" @click="getchangepassword">提交</div>
          </div>
        </div>

        <div v-if="menuIndex == 6">
          <div
            class="zx-box"
            v-if="Object.keys(this.date.sportsman).length === 0"
          >
            <img class="zw" src="../../assets/image/333.png" alt="" />
            <div class="zwxx">暂无信息</div>
          </div>
          <div class="ydy-box" v-else>
            <div
              class="ydy"
              v-for="(item, index) in this.date.sportsman.sportsList"
              :key="index"
            >
              <div class="name-box">
                <div class="ydyxian"></div>
                <div class="name">{{ item }}</div>
              </div>
              <div class="ydybut">
                <div class="ydybutname" @click="getentry(item)">参赛记录</div>
              </div>
            </div>
          </div>
          <el-dialog
            :visible.sync="dialogVisible"
            width="90%"
            :before-close="handleClose"
          >
            <div class="table" style="margin: 0">
              <el-table
                :data="tableData"
                border
                style="width: 100%"
                :header-cell-style="{ background: '#0A6DF3' }"
              >
                <el-table-column label="项目" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.sports }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="matchName"
                  label="赛事名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column label="赛事时间" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.matchDateStart }} 至
                    {{ scope.row.matchDateEnd }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="matchAddressStadium"
                  label="地点"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="gameGroup" label="组别" align="center">
                </el-table-column>
                <el-table-column prop="event" label="小项" align="center">
                </el-table-column>
                <el-table-column
                  prop="games"
                  label="赛次（轮次）"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="delegation"
                  label="代表队"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="names" label="姓名" align="center">
                </el-table-column>
                <el-table-column prop="grade" label="成绩" align="center">
                </el-table-column>
                <el-table-column
                  prop="ranking"
                  label="名次"
                  align="center"
                  width="55"
                >
                </el-table-column>
                <el-table-column prop="remarks" label="备注" align="center">
                </el-table-column>
              </el-table>
            </div>
          </el-dialog>
        </div>

        <personalphotoauthorization
          v-if="menuIndex == 7 && photoau"
          @uploadagain="uploadagain"
        >
        </personalphotoauthorization>

        <photoauthorization
          v-if="menuIndex == 7 && !photoau"
          @photoautoexamine="photoautoexamine"
        ></photoauthorization>
      </div>
    </div>
  </div>
</template>

<script>
import homepage from './homepage.vue'

import personalInformation from './personal-Information.vue'
import record from './record.vue'
import refereeRegistration from './referee-registration.vue'
import refereeDetails from './referee-details.vue'
import personalAuthentication from './personal-authentication.vue'
import match from './match.vue'
import photoauthorization from './photoauthorization.vue'
import personalphotoauthorization from './personal-photoauthorization.vue'
export default {
  components: {
    homepage,

    personalInformation,
    record,
    refereeRegistration,
    refereeDetails,
    personalAuthentication,
    match,
    photoauthorization,
    personalphotoauthorization
  },
  data() {
    return {
      menuIndex: 0,
      step: 1,
      telshow: false,
      passshow: false,
      pas: '******',
      modifyphone: {
        userId: '',
        oldPhone: '',
        newPhone: '',
        phoneCode: '',
        userType: ''
      },
      isCountingDown: false,
      remainingSeconds: 0,
      countdownTimer: null,
      changepassword: {
        userId: '',
        oldPassword: '',
        newPassword: '',
        confirm: '',
        userType: ''
      },
      nameornot: false,
      edit: false,
      id: {},
      userdate: {},
      distinguish: 0,
      isCountingDown2: false,
      date: {
        sportsman: {}
      },
      dialogVisible: false,
      tableData: [],

      photoau: false
    }
  },
  created() {
    this.menuIndex = this.$route.query.menuIndex || 0
    this.nameornot = this.$route.query.nameornot == 'true'
    this.photoau = this.$route.query.photoau == 'true'
    this.step = this.$route.query.step || 1
    this.userInfo()
    this.$store.commit("EXIT_REALN")
  },
  computed: {
    buttonText() {
      if (this.isCountingDown) {
        return `${this.remainingSeconds} 秒后重新获取`;
      } else {
        return '获取验证码';
      }
    },
  },
  methods: {
    userInfo() {
      let date = {
        phone: this.$store.state.logininformation.phone,
        username: this.$store.state.logininformation.username,
        realname: this.$store.state.logininformation.realname
      }
      this.$api.userInfo(date).then(res => {
        res.data.data.phone = this.$decrypt(res.data.data.phone)
        res.data.data.realname = this.$decrypt(res.data.data.realname)
        res.data.data.username = this.$decrypt(res.data.data.username)
        res.data.data.phone = res.data.data.phone
        res.data.data.realname = res.data.data.realname
        this.userdate = res.data.data
      })
    },
    menuClick(key) {
      if (this.$verification(this.$store.state.realnameornot.createTime) && key == 7) {
        return this.$alert('请先完成身份验证并通过审核', '提示', {
          confirmButtonText: '关闭',
          callback: action => { }
        });
      } else if (this.$store.state.realnameornot.caStatus == 0 && key == 7) {
        if (this.$store.state.realnameornot.requeststatus != 1) {
          return this.$alert('请先完成身份验证并通过审核', '提示', {
            confirmButtonText: '关闭',
            callback: action => { }
          });
        }
      } else if (this.$store.state.realnameornot.caStatus == 2 && key == 7) {
        return this.$alert('请先完成身份验证并通过审核', '提示', {
          confirmButtonText: '关闭',
          callback: action => { }
        });
      }

      this.menuIndex = key
      this.step = 1
      this.$store.commit("EXIT_REALN")
      if (key == '3') {
        this.telshow = false
        this.passshow = false
      }
      if (this.$store.state.realnameornot.createTime != null && key == 4) {
        this.nameornot = true
      } else if (this.$store.state.realnameornot.createTime == null && key == 4) {
        this.nameornot = false
      }
      if (key == 6) {
        this.$api.getUserCardInfo({ userId: this.$store.state.userid }).then(res => {
          this.date = res.data.data.result
        })
      }

      if (key == 5) {
        this.telshow = false
        this.passshow = false
        this.changepassword.oldPassword = '',
          this.changepassword.newPassword = '',
          this.changepassword.confirm = ''
        this.modifyphone.newPhone = '',
          this.modifyphone.phoneCode = ''
      }

      if (this.$verification(this.$store.state.realnameornot.headPic) && key == 7) {
        this.photoau = false
      }
      if (!this.$verification(this.$store.state.realnameornot.headPic) && key == 7) {
        this.photoau = true
      }

      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('photoau');
      urlParams.delete('menuIndex');
      const newURL = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newURL); // 更新浏览器地址栏
    },
    recertification() { // 重新验证
      this.nameornot = false
      this.menuIndex = 4
      this.edit = true
      this.distinguish = 1
    },
    recertification1() { // 老版实名认证
      this.nameornot = true
      this.menuIndex = 4
      this.edit = false
    },
    authorization() { // 人脸跳转免冠照
      if (this.$verification(this.$store.state.realnameornot.headPic)) {
        this.photoau = false
      }
      if (!this.$verification(this.$store.state.realnameornot.headPic)) {
        this.photoau = true
      }
      this.menuIndex = 7
    },
    uploadagain() { // 免冠照审核上传
      this.photoau = false
      this.menuIndex = 7
    },
    photoautoexamine() { // 免冠照提交
      this.photoau = true
      this.menuIndex = 7
    },
    newlyadded(row) {
      if (this.$verification(this.$store.state.realnameornot.createTime) || this.$store.state.realnameornot.caStatus == 0) { // 未人脸识别

        if (this.$store.state.realnameornot.createTime && this.$store.state.realnameornot.requeststatus == 1) {
          if (row.index == 6) {
            this.$api.getUserAge({ 'userId': this.$store.state.userid }).then(res => {
              if (res.data.data.result > 65 && row.id.sports != 'weiqi52') {
                return this.$alert('65周岁以上无需进行裁判员注册工作', '提示', {
                  confirmButtonText: '关闭',
                  callback: action => { }
                });
              } else {
                this.id = row.id
                this.step = row.index
              }
            })
          } else {
            this.id = row.id
            this.step = row.index
          }

        } else {
          if (!this.$store.state.realnameornot.createTime) {
            this.$confirm('当前账号尚未身份验证，是否前往身份验证', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.menuIndex = 4
              this.nameornot = false
            }).catch(() => { });

          } else if (this.$store.state.realnameornot.requeststatus == 0) {
            this.$alert('当前账号身份验证审核中，请等待审核完成', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
                this.menuIndex = 4
                this.nameornot = true
              }
            });
          }
          else if (this.$store.state.realnameornot.requeststatus == 2) {
            this.$alert('当前账号身份验证未通过，请先完成身份验证 ', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              callback: action => {
                this.menuIndex = 4
                this.nameornot = true
              }
            });
          }
        }
      } else { // 通过人脸识别
        if (this.$store.state.realnameornot.caStatus == 2) {
          this.$alert('当前账号人脸识别未通过，请重新认证', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.menuIndex = 4
              this.nameornot = true
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 0 && this.$verification(this.$store.state.realnameornot.headPic)) {
          this.$alert('当前账号未上传免冠照，请上传', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.menuIndex = 7
              this.photoau = false
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 0 && !this.$verification(this.$store.state.realnameornot.headPic)) {
          this.$alert('当前账号免冠照审核中，请等待审核完成', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.menuIndex = 7
              this.photoau = true
            }
          });
        } else if (this.$store.state.realnameornot.requeststatus == 2) {
          this.$alert('当前账号免冠照审核未通过，请重新上传', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.menuIndex = 7
              this.photoau = true
            }
          });
        } else if (this.$store.state.realnameornot.caStatus == 1 && this.$store.state.realnameornot.requeststatus == 1) {
          if (row.index == 6) {
            this.$api.getUserAge({ 'userId': this.$store.state.userid }).then(res => {
              if (res.data.data.result > 65 && row.id.sports != 'weiqi52') {
                return this.$alert('65周岁以上无需进行裁判员注册工作', '提示', {
                  confirmButtonText: '关闭',
                  callback: action => { }
                });
              } else {
                this.id = row.id
                this.step = row.index
              }
            })
          } else {
            this.id = row.id
            this.step = row.index
          }
        }
      }
    },
    toexamine() { // 裁判员注册
      this.menuIndex == 1
      this.step = 1
    },
    modifytel() {
      this.telshow = true
      this.passshow = false
      this.modifyphone.oldPhone = this.userdate.phone
      this.modifyphone.userId = this.$store.state.userid
    },
    modifypass() {
      this.passshow = true
      this.telshow = false
      this.changepassword.userId = this.$store.state.userid
    },
    startCountdown() {
      if (this.modifyphone.newPhone == '') {
        return this.$message.error('请输入新手机号')
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(this.modifyphone.newPhone)) {
        return this.$message.error('手机号码格式错误')
      }
      if (this.isCountingDown2) {
        return;
      }
      if (this.isCountingDown) {
        return;
      }
      let data = {
        phone: this.modifyphone.newPhone,
        timeOut: 60,
        smsmode: 2 //忘记密码
      }
      this.isCountingDown2 = true
      this.$api.getPhoneCode(data).then(res => {
        this.isCountingDown = true;
        this.remainingSeconds = 60;

        this.countdownTimer = setInterval(() => {
          this.remainingSeconds--;

          if (this.remainingSeconds <= 0) {
            this.stopCountdown();
          }
        }, 1000);
      }).catch(error => {
        this.isCountingDown2 = false;
        console.error('Failed to request verification code:', error);
      })
    },
    stopCountdown() {
      clearInterval(this.countdownTimer);
      this.isCountingDown = false;
      this.remainingSeconds = 0;
      this.isCountingDown2 = false;
    },
    getchangepassword() {
      this.changepassword.userType = this.$store.state.userType
      const pas = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*_-]).{8,16}$/
      if (this.changepassword.oldPassword == '') {
        return this.$message.error('请输入旧密码')
      }
      if (this.changepassword.newPassword == '') {
        return this.$message.error('请输入新密码')
      }
      if (!pas.test(this.changepassword.newPassword)) {
        return this.$message.error('新密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
      }
      if (this.changepassword.confirm == '') {
        return this.$message.error('请输入确认密码')
      }
      if (!pas.test(this.changepassword.confirm)) {
        return this.$message.error('密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
      }
      if (this.changepassword.newPassword != this.changepassword.confirm) {
        return this.$message.error('新密码与确认密码输入错误，不一致')
      }

      let encryptedInfo = {
        userId: this.$encrypt(this.changepassword.userId),
        oldPassword: this.$encrypt(this.changepassword.oldPassword),
        newPassword: this.$encrypt(this.changepassword.newPassword),
        confirm: this.$encrypt(this.changepassword.confirm),
        userType: this.$encrypt(this.changepassword.userType),
      }

      this.$api.updatePassword(encryptedInfo).then(res => {
        this.$message.success('修改成功')
        this.passshow = false
        this.changepassword.oldPassword = '',
          this.changepassword.newPassword = '',
          this.changepassword.confirm = ''
      })
    },
    getiphone() {
      this.modifyphone.userType = this.$store.state.userType
      if (this.modifyphone.newPhone == '') {
        return this.$message.error('请输入新手机号')
      }
      const regex = /^1[3456789]\d{9}$/
      if (!regex.test(this.modifyphone.newPhone)) {
        return this.$message.error('新手机号格式错误')
      }
      if (this.modifyphone.phoneCode == '') {
        return this.$message.error('请输入验证码')
      }

      let encryptedInfo = {
        userId: this.$encrypt(this.modifyphone.userId),
        oldPhone: this.$encrypt(this.modifyphone.oldPhone),
        newPhone: this.$encrypt(this.modifyphone.newPhone),
        phoneCode: this.$encrypt(this.modifyphone.phoneCode),
        userType: this.$encrypt(this.modifyphone.userType),
      }

      this.$api.updatePhone(encryptedInfo).then(res => {
        this.$message.success('修改成功')

        this.$api.checkLogout({
          accessToken: this.$store.state.token
        }).then(res => {
          setTimeout(() => {
            this.$store.commit("EXIT_TOKEN")
            this.$router.push({
              path: '/login'
            })
          }, 500)
        })

        this.telshow = false
        this.modifyphone.newPhone = '',
          this.modifyphone.phoneCode = ''
        this.userInfo()
      })
    },
    getentry(item) {
      this.dialogVisible = true
      let data = JSON.parse(JSON.stringify(this.date.sportsman))
      data.sports = item
      this.$api.getSportsmanScore(data).then(res => {
        res.data.data.result.forEach(item => {
          item.names = this.$decrypt(item.names || '')
          item.matchAddressStadium = item.addresses.map(items => {
            return items.matchAddressStadium
          }).join('、')
        })
        this.tableData = res.data.data.result
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.box {
	width: 1222PX;
	margin: 0 auto;
	min-height: 50vh;
	background: #F8FBFF;
	border-radius: 22PX;
	margin-top: 59PX;
	display: flex;
	margin-bottom: 20PX;
}

.zou {
	width: 200PX;
	min-height: 50vh;
	background: #EAF3FF;
	border-radius: 22PX;
	padding-bottom: 20PX;

	.label_1 {
		width: 28PX;
		height: 27PX;
		margin: 19PX 0 0 23PX;
	}

	.group_2 {
		width: 200PX;
		height: 65PX;
		background: linear-gradient(150deg, #164B92, #1669FF);
		border-radius: 0PX 23PX 23PX 0PX;
		margin-top: 31PX;
	}

	.text_8 {
		width: 97PX;
		height: 22PX;
		overflow-wrap: break-word;
		color: rgba(255, 255, 255, 1);
		font-size: 22PX;
		font-family: MicrosoftYaHei;
		font-weight: normal;
		text-align: left;
		white-space: nowrap;
		line-height: 36PX;
		margin: 23PX 41PX 0 11PX;
		cursor: pointer;
	}

	.text_88 {
		position: relative;
		top: 13PX;
	}

	.group_36 {
		width: 135PX;
		height: 21PX;
		margin: 57PX 0 0 22PX;
		display: flex;
		align-items: center;
	}

	.thumbnail_2 {
		width: 8PX;
		height: 20PX;
	}

	.text_9 {
		width: 101PX;
		overflow-wrap: break-word;
		color: rgba(22, 75, 146, 1);
		font-size: 19PX;
		font-family: SourceHanSansCN-Bold;
		font-weight: 700;
		text-align: left;
		white-space: nowrap;
		margin-top: 1PX;
		margin-left: 26PX;
		cursor: pointer;
	}

	.group_37 {
		width: 100PX;
		height: 17PX;
		margin: 24PX 0 0 24PX;
		display: flex;
		align-items: center;
	}

	.block_2 {
		background-color: rgba(110, 110, 110, 1);
		border-radius: 50%;
		width: 4PX;
		height: 4PX;
		margin-top: 7PX;
	}

	.text_10 {
		width: 69PX;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 17PX;
		font-family: SourceHanSansCN-Regular;
		font-weight: normal;
		text-align: left;
		white-space: nowrap;
		line-height: 36PX;
		margin-left: 27PX;
		cursor: pointer;
	}

	.group_38 {
		width: 100PX;
		height: 17PX;
		margin: 24PX 0 0 24PX;
	}

	.section_1 {
		background-color: rgba(110, 110, 110, 1);
		border-radius: 50%;
		width: 4PX;
		height: 4PX;
		margin-top: 9PX;
	}

	.text_11 {
		width: 69PX;
		height: 17PX;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 17PX;
		font-family: SourceHanSansCN-Regular;
		font-weight: normal;
		text-align: left;
		white-space: nowrap;
		line-height: 36PX;
	}

	.image-text_33 {
		width: 140PX;
		height: 23PX;
		margin: 43PX 0 0 17PX;
		display: flex;
		align-items: center;
	}

	.label_2 {
		width: 27PX;
		height: 23PX;
	}

	.text-group_1 {
		width: 101PX;
		overflow-wrap: break-word;
		color: rgba(22, 75, 146, 1);
		font-size: 19PX;
		font-family: SourceHanSansCN-Bold;
		font-weight: 700;
		text-align: left;
		white-space: nowrap;
		margin-top: 3PX;
		margin-left: 12PX;
	}

	.group_39 {
		width: 98PX;
		height: 17PX;
		margin: 19PX 0 0 24PX;
	}

	.text_12 {
		width: 67PX;
		height: 17PX;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 17PX;
		font-family: SourceHanSansCN-Regular;
		font-weight: normal;
		text-align: left;
		white-space: nowrap;
		line-height: 36PX;
	}

	.image-text_34 {
		width: 115PX;
		height: 21PX;
		margin: 34PX 0 0 15PX;
		display: flex;
		align-items: center;
	}

	.image_4 {
		width: 20PX;
		height: 20PX;
	}

	.text-group_2 {
		width: 80PX;
		overflow-wrap: break-word;
		color: rgba(22, 75, 146, 1);
		font-size: 19PX;
		font-family: SourceHanSansCN-Bold;
		font-weight: 700;
		text-align: left;
		white-space: nowrap;
		margin-left: 21PX;
	}

	.group_40 {
		width: 100PX;
		height: 17PX;
		margin: 14PX 0 0 24PX;
	}

	.text_13 {
		width: 69PX;
		height: 17PX;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 17PX;
		font-family: SourceHanSansCN-Regular;
		font-weight: normal;
		text-align: left;
		white-space: nowrap;
		line-height: 36PX;
	}

	.group_41 {
		width: 99PX;
		height: 16PX;
		margin: 20PX 0 1832PX 25PX;
	}

	.text_14 {
		width: 69PX;
		height: 16PX;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 17PX;
		font-family: SourceHanSansCN-Regular;
		font-weight: normal;
		text-align: left;
		white-space: nowrap;
		line-height: 36PX;
	}
}

.you {
	width: 1022PX;
	min-height: 50vh;
	padding: 0 42PX;
}

.back {
	margin: 0 auto;
	height: 450PX;
	background: #F8FBFF;
	border-radius: 22PX;
	padding-top: 52PX;
}

.tel {
	display: flex;
	align-items: center;
	margin-left: 106PX;
}

.tel img {
	width: 17.3PX;
	height: 27.7PX;
}

.telnumber {
	font-size: 18PX;
	font-weight: 400;
	color: #333333;
	margin-left: 25PX;
}

.tel .asterisk {
	font-size: 25PX;
	font-weight: 400;
	color: #E6101C;
	margin-left: 5PX;
	margin-right: 59PX;
}

.tel input {
	width: 312PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	padding-left: 10PX;
	font-size: 18PX;
}

.tel .but {
	width: 145PX;
	height: 42PX;
	background: #0063FF;
	border-radius: 7PX;
	font-size: 18PX;
	font-weight: 400;
	color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 22PX;
	cursor: pointer;
}

.tel .but2 {
	width: 104PX;
	height: 42PX;
	background: #F3F8FF;
	border: 1PX solid #2368E1;
	border-radius: 7PX;
	font-size: 18PX;
	font-weight: 400;
	color: #2368E1;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 22PX;
	cursor: pointer;
}

.tel .el-input {
	width: 312PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	padding-left: 10PX;
	font-size: 18PX;
}

.tel ::v-deep .el-input__inner {
	background: #FFFFFF;
	border: none;
	border-radius: 7PX;
}

.submitto {
	width: 654PX;
	height: 54PX;
	background-image: linear-gradient(to right, #6FB2FF, #095FFF);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25PX;
	font-weight: bold;
	color: #FFFFFF;
	margin-left: 106PX;
	margin-top: 43PX;
	cursor: pointer;
}

::v-deep .el-menu-item.is-active {
	color: #408ED6;
}

.zx-box {
	margin-top: 202PX;
	display: flex;
	flex-direction: column;
	align-items: center;

	.zw {
		width: 257PX;
		height: 162PX;
	}

	.zwxx {
		font-family: SourceHanSansCN;
		font-weight: 500;
		font-size: 22PX;
		color: #989898;
		margin-top: 27PX;
		margin-bottom: 40PX;
	}
}

.ydy-box {
	margin: 47PX 0 0 0;
	width: 950PX;
	display: flex;
	flex-wrap: wrap;


	.ydy {
		width: 293PX;
		height: 172PX;
		background: url('../../assets/image/555.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-right: 32PX;
		margin-bottom: 40PX;

		.name-box {
			display: flex;
			align-items: center;
			margin-left: 26PX;
			padding-top: 30PX;

			.ydyxian {
				width: 6PX;
				height: 19PX;
				background: #417BC8;
				border-radius: 3PX;
			}

			.name {
				font-family: SourceHanSansCN;
				font-weight: bold;
				font-size: 26PX;
				color: #408BFF;
				line-height: 36PX;
				background: linear-gradient(150deg, #336EBC 0.146484375%, #81B7FF 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				margin-left: 21PX;
			}
		}

		.ydybut {
			width: 139PX;
			height: 36PX;
			background: url('../../assets/image/444.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			display: flex;
			align-items: center;
			margin-top: 45PX;
			margin-left: 104PX;
			cursor: pointer;

			.ydybutname {
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 22PX;
				color: #629AE4;
				margin-left: 40PX;
			}
		}
	}

	.ydy:nth-child(3n) {
		margin-right: 0;
	}
}

.table ::v-deep .el-table thead {
	color: #fff;
	font-size: 16PX;
	font-weight: 500;
}

.table ::v-deep .el-table__header-wrapper {
	border-top-left-radius: 10PX;
	border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
	border-top-left-radius: 10PX;
	border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
	border-bottom: 1PX solid #333333;
	border-right: 1PX solid #333333;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
	border-color: #333333;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
	background-color: #333333;
}
</style>
